import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import Logo from "../Logo/Logo";
import LangBtn from "../LangBtn/LangBtn";
import arabicLogo from "../../assets/icons/soom-ar.png";
import englishLogo from "../../assets/icons/soom-en.png";
import iconAqari from "../../assets/icons/aqariIcon.svg";
import cookies from "js-cookie";

const Header = () => {
  const { width } = useWindowSize();
  const currentLanguageCode = cookies.get("i18next") || "en";

  return (
    <div className="super_header">
      <header className="header">
        {width < 1091 && (
          <div className={"header_responsive"}>
            <div className="left_responsive">
              <LangBtn />
            </div>
            {/* <img
              src={currentLanguageCode === "en" ? englishLogo : arabicLogo}
              alt=""
            /> */}
            <img
              className="header_responsive_logo"
              src={currentLanguageCode === "en" ? englishLogo : arabicLogo}
              alt=""
            />{" "}
          </div>
        )}
        {width > 1091 && (
          <>
            <Logo />
            <div className="header__right">
              <LangBtn />
            </div>
          </>
        )}
      </header>
    </div>
  );
};

export default Header;
